body {
  margin: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

@font-face {
  font-family: 'Corsica MX Medium Cond';
  src: local('CorsicaMXMediumCond'), url(./fonts/CorsicaMXMediumCond.otf) format('opentype');
}

@font-face {
  font-family: 'Corsica LX Bold';
  src: local('CorsicaLXBold'), url(./fonts/CorsicaLXBold.otf) format('opentype');
}

@font-face {
  font-family: 'Gothic Bold';
  src: local('GothicBold'), url(./fonts/GOTHICB.TTF) format('opentype');
}

@font-face {
  font-family: 'Gothic';
  src: local('Gothic'), url(./fonts/GOTHIC_0.TTF) format('opentype');
}

@font-face {
  font-family: 'Arial Bold';
  src: local('Gothic'), url(./fonts/ARIALBD.TTF) format('opentype');
}
